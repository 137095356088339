import axios from "axios";

let apiURL;

if (process.env.NODE_ENV === "development") {
  apiURL = "https://stenkoll.stendahls.se:4000/api";
} else {
  if (window.location.protocol === "https:") {
    apiURL = "https://" + window.location.hostname + ":4000/api";
  } else {
    apiURL = "http://" + window.location.hostname + ":4000/api";
  }
}

// USER
export async function getUser() {
  try {
    const response = await axios.get(`${apiURL}/user-info`);

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function loginUser(email, password, azure) {
  try {
    const response = await axios.post(`${apiURL}/login`, {
      email: email,
      password: password,
      azure: azure,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getAllUsers() {
  try {
    const response = await axios.get(`${apiURL}/users`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createUser(newUser) {
  try {
    const response = await axios.post(`${apiURL}/users`, newUser);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function editUser(userId, fullname, email, global_admin) {
  try {
    const response = await axios.put(`${apiURL}/users/${userId}`, {
      fullname: fullname,
      email: email,
      global_admin: global_admin,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteUser(userId) {
  try {
    const response = await axios.delete(`${apiURL}/users/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function uploadUserImage(formData) {
  try {
    const response = await axios.post(`${apiURL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}

// TRAINING
export async function getAllTrainings() {
  try {
    const response = await axios.get(`${apiURL}/trainings`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createTraining(title, description, short_url) {
  try {
    const response = await axios.post(`${apiURL}/trainings`, {
      title: title,
      description: description,
      short_url: short_url,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function editTraining(trainingId, trainingData) {
  try {
    const response = await axios.put(
      `${apiURL}/trainings/${trainingId}`,
      trainingData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteTraining(trainingId) {
  try {
    const response = await axios.delete(`${apiURL}/trainings/${trainingId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getTraining(short_url) {
  try {
    const response = await axios.get(`${apiURL}/trainings/${short_url}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// TRAINING SECTIONS
export async function fetchSectionsByTraining(trainingId) {
  try {
    const response = await axios.get(
      `${apiURL}/sections_by_training/${trainingId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function createTrainingSection(sectionData) {
  try {
    const response = await axios.post(
      `${apiURL}/training_sections`,
      sectionData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function editTrainingSection(sectionId, sectionData) {
  try {
    const response = await axios.put(
      `${apiURL}/training_sections/${sectionId}`,
      sectionData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function deleteTrainingSection(sectionId) {
  try {
    const response = await axios.delete(
      `${apiURL}/training_sections/${sectionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// SUB SECTIONS (MODULER)
export async function fetchSubSection(sectionId, subSectionId) {
  try {
    const response = await axios.get(
      `${apiURL}/training_sub_sections/${sectionId}/${subSectionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function editSubSection(
  sectionId,
  subSectionId,
  updatedSubSectionData
) {
  try {
    const response = await axios.put(
      `${apiURL}/training_sub_sections/${sectionId}`,
      { subSectionId, updatedSubSectionData }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function uploadTrainingPdf(sectionId, subSectionId, file) {
  try {
    const formData = new FormData();
    formData.append("pdf", file);
    const response = await axios.post(
      `${apiURL}/training_sub_sections/${sectionId}/${subSectionId}/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updatePdfUrl(sectionId, subSectionId, newPdfUrl) {
  try {
    const response = await axios.post(
      `${apiURL}/training_sub_sections/${sectionId}/${subSectionId}/update`,
      { newPdfUrl }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function listFilesInUploadsDirectory() {
  try {
    const response = await axios.get(`${apiURL}/uploads`);
    const files = response.data.files.filter((file) => !file.startsWith("."));
    return files;
  } catch (error) {
    throw error;
  }
}

export async function deleteFile(filename) {
  try {
    const response = await axios.delete(`${apiURL}/uploads/${filename}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

// USER RESPONSE
// export async function fetchUserResponsesForTrainingSection(trainingSectionId) {
//   try {
//     const response = await axios.get(
//       `${apiURL}/userresponse/training_section/${trainingSectionId}`
//     );
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// }

export async function fetchAllStats() {
  try {
    const response = await axios.get(`${apiURL}/user-stats`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function fetchUserResponsesForUser(userId) {
  try {
    const response = await axios.get(`${apiURL}/userresponse/user/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveUserResponse(userResponse) {
  try {
    const response = await axios.post(`${apiURL}/userresponse`, userResponse);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function getCorrectAnswers(userId, trainingSectionId) {
  try {
    const response = await axios.get(
      `${apiURL}/correct-answers/${userId}/${trainingSectionId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function saveUserAcceptResponse(userResponse) {
  try {
    const response = await axios.post(`${apiURL}/accept`, userResponse);
    return response.data;
  } catch (error) {
    throw error;
  }
}
