<template>
  <section
    v-if="trainingData"
    class="overflow-x-hidden h-full dark:bg-gray-900"
  >
    <div
      class="max-w-screen-xl px-0 py-0 mx-auto lg:gap-8 xl:gap-0 lg:py-12 grid-cols-1 lg:grid-cols-12"
    >
      <div class="px-4 mr-auto">
        <Tilt class="drop-shadow-3xl hover:drop-shadow-4xl" :options="tiltOpt">
          <Certificate :trainingData="trainingData" />
        </Tilt>
        <button
          @click="printPage"
          class="mt-10 inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
        >
          Skriv ut
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import {
  getTraining,
  fetchSectionsByTraining,
  fetchUserResponsesForUser,
} from "@/api/index.js";
import { useUserReponse } from "@/store/responses.js";
import { useUserStore } from "@/store/user";
import Tilt from "vanilla-tilt-vue";
import Certificate from "@/components/CertificateComponent.vue";

export default {
  props: {
    training: String,
  },
  data() {
    return {
      training_shorturl: this.$route.params.training,
      trainingData: {},
      sections: [],
      numberOfAllQuestions: 0,
      allUserStats: [],
      listQuestions: [],
      howManyTrainingsDone: 0,
      trainingIsCompleted: false,
      trainingIsApproved: false,
      bestPercentage: 0,
      acceptedYear: "",
      tiltOpt: {
        reverse: true, // reverse the tilt direction
        max: 5, // max tilt rotation (degrees)
        startX: 0, // the starting tilt on the X axis, in degrees.
        startY: 0, // the starting tilt on the Y axis, in degrees.
        perspective: 1500, // Transform perspective, the lower the more extreme the tilt gets.
        scale: 1, // 2 = 200%, 1.5 = 150%, etc..
        speed: 1000, // Speed of the enter/exit transition
        transition: true, // Set a transition on enter/exit.
        reset: true, // If the tilt effect has to be reset on exit.
        easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
        glare: true, // if it should have a "glare" effect
        "max-glare": 0.8, // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
        "glare-prerender": false, // false = VanillaTilt creates the glare elements for you, otherwise
        "mouse-event-element": null, // css-selector or link to HTML-element what will be listen mouse events
        gyroscope: true, // Boolean to enable/disable device orientation detection,
        gyroscopeMinAngleX: -45, // This is the bottom limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the left border of the element;
        gyroscopeMaxAngleX: 45, // This is the top limit of the device angle on X axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the right border of the element;
        gyroscopeMinAngleY: -45, // This is the bottom limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the top border of the element;
        gyroscopeMaxAngleY: 45, // This is the top limit of the device angle on Y axis, meaning that a device rotated at this angle would tilt the element as if the mouse was on the bottom border of the element;
      },
    };
  },
  components: {
    Tilt,
    Certificate,
  },
  mounted() {
    this.fetchTraining();
  },
  computed: {
    statsForThisTraining() {
      const statsForThisTraining = this.allUserStats.filter(
        (stat) => stat.training_section === this.trainingData._id
      );
      return statsForThisTraining;
    },
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    amountOfTotalQuestions() {
      return useUserReponse().activeTraining.amountOfQuestions;
    },
    user() {
      return useUserStore().user;
    },
  },
  watch: {
    user: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue._id) {
          if (useUserReponse().userResponse.response.length === 0) {
            this.getAllStats();
          }
        }
      },
    },
  },
  methods: {
    async fetchTraining() {
      try {
        const response = await getTraining(this.training_shorturl);
        this.trainingData = response;
        this.fetchCourses(response._id);
      } catch (error) {
        console.error("Error fetching training:", error);
      }
    },
    async fetchCourses(id) {
      try {
        this.sections = await fetchSectionsByTraining(id);
        this.totalQuestions();
        this.getAllStats();
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    },
    async getAllStats() {
      try {
        this.howManyTrainingsDone = 0;
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
        this.allUserStats.forEach((stat) => {
          if (stat.training_section === this.trainingData._id) {
            this.howManyTrainingsDone++;
          }
        });
        this.isTrainingCompleted(this.trainingData);
        this.isTrainingApproved(this.trainingData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    totalQuestions() {
      this.numberOfAllQuestions = 0;
      this.sections.forEach((s) => {
        s.sub_sections.forEach((ss) => {
          if (ss.type === "questions") {
            ss.data.questions.forEach((question) => {
              this.listQuestions.push(question);
              this.numberOfAllQuestions++;
            });
          }
        });
      });
    },
    isTrainingCompleted(training) {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === training._id
      );
      this.trainingIsCompleted = false;
      matchingEntries.some((entry) => {
        if (entry.responses.length !== 0) {
          this.trainingIsCompleted = true;
        }
      });
    },
    isTrainingApproved(training) {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === training._id
      );
      this.trainingIsApproved = false;
      return matchingEntries.some((entry) => {
        if (entry.responses.length !== 0) {
          let maxCorrect = 0;
          let totalQuestions = 0;
          entry.responses.forEach((response) => {
            totalQuestions++;
            if (response.correct[0]) {
              maxCorrect++;
            }
          });
          const bestPercentage =
            totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
          this.bestPercentage = bestPercentage;
          return (this.trainingIsApproved =
            bestPercentage >= training.approved_threshold);
        }
      });
    },
    getAcceptedDate(training) {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === training._id
      );
      let latestTimestamp = null;
      matchingEntries.forEach((entry) => {
        const entryTimestamp = new Date(entry.timestamp);
        if (!latestTimestamp || entryTimestamp > latestTimestamp) {
          latestTimestamp = entryTimestamp;
        }
      });
      if (latestTimestamp) {
        const date = new Date(latestTimestamp);
        const formattedDay = date.getDate();
        const formattedMonth = new Intl.DateTimeFormat("en", {
          month: "long",
        }).format(date);
        const currentYear = new Date().getFullYear();
        const formattedYear =
          date.getFullYear() === currentYear ? "" : ` ${date.getFullYear()}`;
        return `${formattedDay} ${formattedMonth}${formattedYear}`;
      }
      return "";
    },
    printPage() {
      window.print();
    },
  },
};
</script>
