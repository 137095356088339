<template>
  <div
    class="p-4 block sm:flex items-center justify-between border-b border-gray-200 lg:mt-1.5 dark:border-gray-700"
  >
    <div class="w-full mb-1">
      <div class="sm:flex">
        <div class="">
          <form class="flex items-center">
            <label for="simple-search" class="sr-only">Search</label>
            <div class="relative w-full">
              <div
                class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="currentColor"
                  viewbox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                v-model="searchQuery"
                type="text"
                id="simple-search"
                class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Sök"
              />
            </div>
          </form>
        </div>
        <div class="flex items-center ml-auto space-x-2 sm:space-x-3">
          <button
            type="button"
            @click="toggleUserModal"
            class="hidden sm:inline-flex items-center justify-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 sm:w-auto dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            <Icon class="w-4 h-4 mr-0 sm:mr-2" icon="ph-user-plus" />
            <span class="hidden sm:block">Lägg till användare</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="relative overflow-x-auto shadow">
    <table class="w-full divide-y divide-gray-200 dark:divide-gray-600">
      <thead class="bg-gray-100 dark:bg-gray-700">
        <tr>
          <th
            scope="col"
            class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400 hover:cursor-pointer"
            @click="sort('fullname')"
          >
            <div class="flex">
              Namn
              <span v-if="sortColumn === 'fullname'" class="ml-1">
                <Icon
                  v-if="sortDirection === 'asc'"
                  class="w-3 h-3"
                  icon="ph-caret-down-fill"
                />
                <Icon v-else class="w-3 h-3" icon="ph-caret-up-fill" />
              </span>
              <span v-else class="invisible group-hover/item:visible ml-1">
                <Icon class="w-3 h-3 opacity-40" icon="ph-caret-down-fill" />
              </span>
            </div>
          </th>
          <th
            scope="col"
            class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
          >
            Typ
          </th>
          <th
            scope="col"
            class="p-4 text-xs font-medium text-left text-gray-500 uppercase dark:text-gray-400"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody
        class="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700"
      >
        <tr v-for="user in sortedUsers" :key="user._id">
          <td class="flex items-center p-4 mr-12 space-x-6">
            <img
              class="w-10 h-10 rounded-full"
              :src="getUserImage(user)"
              alt="avatar"
            />
            <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
              <div
                class="text-base font-semibold text-gray-900 dark:text-white"
              >
                {{ user.fullname }}
              </div>
              <div class="text-sm font-normal text-gray-500 dark:text-gray-400">
                {{ user.email }}
              </div>
            </div>
          </td>
          <td class="p-4 text-base font-medium text-gray-900 dark:text-white">
            {{ user.global_admin ? "Admin" : "Medlem" }}
          </td>
          <td class="p-4 space-x-2">
            <button
              type="button"
              @click="editUser(user)"
              class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <Icon class="w-4 h-4 mr-2" icon="ph-pen" />
              Ändra
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Modals -->
  <div
    id="add-user-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Ny användare
          </h3>
          <button
            @click="toggleUserModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form @submit.prevent="createUser" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Namn</label
              >
              <input
                type="text"
                name="name"
                id="name"
                v-model="newUser.fullname"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="För- och Efternamn"
                required
              />
            </div>
            <div class="col-span-2">
              <label
                for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Mailadress</label
              >
              <input
                type="email"
                name="email"
                id="email"
                v-model="newUser.email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="fornamn@mail.com"
                required
              />
            </div>
            <div class="col-span-2">
              <label
                for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Lösenord</label
              >
              <input
                type="password"
                name="password"
                id="password"
                v-model="newUser.password"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="smooth-APPLE-stocks-57"
                required
              />
            </div>
            <div
              v-if="errorText"
              class="col-span-2 flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              <svg
                class="flex-shrink-0 inline w-4 h-4 me-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"
                />
              </svg>
              <span class="sr-only">Info</span>
              <div>
                {{ errorText }}
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="text-white inline-flex items-center bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-blue-800"
          >
            <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:plus" />
            Skapa användare
          </button>
        </form>
      </div>
    </div>
  </div>
  <div
    id="edit-user-modal"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-60 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
        >
          <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
            Ändra användare
          </h3>
          <button
            @click="toggleEditModal"
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white outline-none"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <form @submit.prevent="updateUser" class="p-4 md:p-5">
          <div class="grid gap-4 mb-4 grid-cols-2">
            <div class="col-span-2">
              <label
                for="name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >För- och Efternamn</label
              >
              <input
                type="text"
                name="title"
                id="title"
                v-model="editedUser.fullname"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="För- och Efternamn"
                required
              />
            </div>
            <div class="col-span-2">
              <label
                for="description"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >Email</label
              >
              <input
                type="email"
                name="email"
                id="email"
                v-model="editedUser.email"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="fornamn@mail.com"
                required
              />
            </div>
            <div class="col-span-2">
              <label class="inline-flex items-center mb-5 cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  v-model="editedUser.global_admin"
                />
                <div
                  class="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:w-5 after:h-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                ></div>
                <span
                  class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >Admin 😎</span
                >
              </label>
            </div>
          </div>
          <div class="flex justify-between">
            <button
              type="submit"
              class="text-white inline-flex items-center bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:pen" />
              Spara
            </button>
            <button
              @click="deleteUser(editedUser._id)"
              class="text-white inline-flex items-center bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-blue-800"
            >
              <Icon class="w-4 h-4 ml-0 mr-2" icon="ph:minus-circle" />
              Ta bort
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAllUsers,
  createUser,
  editUser,
  deleteUser,
  getAllTrainings,
} from "@/api/index.js";
import { initFlowbite, Modal } from "flowbite";
import { Icon } from "@iconify/vue";
import defaultImage from "@/assets/img/default.png";

export default {
  emits: ["scrollToTop"],
  data() {
    return {
      newUser: {
        fullname: "",
        email: "",
        password: "",
        global_admin: false,
      },
      users: [],
      add_user_modal: null,
      edit_user_modal: null,
      searchQuery: "",
      editedUser: {
        _id: null,
        fullname: "",
        email: "",
        global_admin: "",
      },
      errorText: "",
      trainings: [],
      sortColumn: "fullname",
      sortDirection: "asc",
    };
  },
  components: {
    Icon,
  },
  methods: {
    toggleUserModal() {
      this.add_user_modal.toggle();
    },
    toggleEditModal() {
      this.edit_user_modal.toggle();
    },
    getUserImage(user) {
      const ad = user.azure_ad;
      if (ad && ad.profile_image !== undefined) {
        try {
          const bufferData = ad.profile_image.data;
          if (!bufferData || !Array.isArray(bufferData)) {
            console.error("Invalid profile image data");
            return;
          }
          const uint8Array = new Uint8Array(bufferData);
          const base64String = btoa(
            String.fromCharCode.apply(null, uint8Array)
          );
          const profileImageUrl = `data:image/jpeg;base64,${base64String}`;
          return profileImageUrl;
        } catch (error) {
          console.error("Error decoding profile image:", error);
        }
      } else {
        return defaultImage;
      }
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortDirection = "asc";
      }
    },
    async getAllUsers() {
      try {
        const users = await getAllUsers();
        this.users = users;
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    async createUser() {
      try {
        await createUser(this.newUser);
        this.newUser.fullname = "";
        this.newUser.email = "";
        this.newUser.password = "";
        this.newUser.global_admin = "";
        this.getAllUsers();
        this.toggleUserModal();
      } catch (error) {
        this.errorText = error.response.data.message;
        console.error("Error creating user:", error);
      }
    },
    async editUser(user) {
      this.editedUser._id = user._id;
      this.editedUser.fullname = user.fullname;
      this.editedUser.email = user.email;
      this.editedUser.global_admin = user.global_admin;
      this.toggleEditModal();
    },
    async updateUser() {
      try {
        await editUser(
          this.editedUser._id,
          this.editedUser.fullname,
          this.editedUser.email,
          this.editedUser.global_admin
        );
        this.users = await getAllUsers();
        this.toggleEditModal();
      } catch (error) {
        console.error("Error updating user:", error);
      }
    },
    async deleteUser(userId) {
      try {
        await deleteUser(userId);
        this.users = await getAllUsers();
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    },
    async fetchAllTrainings() {
      try {
        this.trainings = await getAllTrainings();
      } catch (error) {
        console.error("Error fetching trainings:", error);
      }
    },
  },
  mounted() {
    initFlowbite();
    const addModal = document.getElementById("add-user-modal");
    const editModal = document.getElementById("edit-user-modal");

    const options = {
      placement: "bottom-right",
      backdrop: "dynamic",
      backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-50",
      closable: true,
      onHide: () => {},
      onShow: () => {},
      onToggle: () => {},
    };

    const addInstanceOptions = {
      id: "add-user-modal",
      override: true,
    };

    const editInstanceOptions = {
      id: "edit-user-modal",
      override: true,
    };

    this.add_user_modal = new Modal(addModal, options, addInstanceOptions);
    this.edit_user_modal = new Modal(editModal, options, editInstanceOptions);
    this.getAllUsers();
    this.fetchAllTrainings();
  },
  computed: {
    sortedUsers() {
      return this.filteredUsers.sort((a, b) => {
        if (this.sortColumn === "fullname") {
          return this.sortDirection === "asc"
            ? a.fullname.localeCompare(b.fullname)
            : b.fullname.localeCompare(a.fullname);
        }
      });
    },
    filteredUsers() {
      const query = this.searchQuery.toLowerCase();
      return this.users.filter((user) => {
        return user.fullname
          ? user.fullname.toLowerCase().includes(query)
          : "#" || user.email.toLowerCase().includes(query);
      });
    },
  },
};
</script>
