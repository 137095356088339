<template>
  <div class="flex h-dvh lg:h-screen">
    <div class="bg-white dark:bg-gray-900 w-full overflow-auto">
      <form @submit.prevent="loginUser">
        <div
          class="form-body lg:max-w-xl mx-auto lg:p-20 p-8 lg:mt-20 mt-5 space-y-8"
        >
          <div class="form-head cursor-pointer" @click="$router.push('/')">
            <img src="../../../assets/logo/logo.svg" alt="" class="w-10" />
          </div>
          <div class="space-y-3">
            <h2 class="dark:text-white font-semibold text-gray-800 text-4xl">
              Välkommen till Stenkoll<span class="text-primary">.</span>
            </h2>
            <p class="dark:text-gray-400 text-gray-700">
              Stendarling? Vänligen logga in med
            </p>
          </div>
          <button
            type="button"
            class="dark:text-white text-gray-700 flex justify-center gap-2 dark:bg-gray-700 bg-gray-100 hover:bg-gray-100/50 p-2 w-full rounded-md"
          >
            <img class="w-8" src="../../../assets/logo/ms-logo.svg" alt="" />
            <p @click="signIn" class="mt-1 dark:text-white text-gray-700">
              Logga in med Microsoft
            </p>
          </button>
          <span class="flex items-center justify-center space-x-2">
            <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
            <span class="font-normal text-gray-500">eller </span>
            <span class="h-px dark:bg-gray-600 bg-gray-200 w-full"></span>
          </span>
          <div class="space-y-5">
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="email"
                name="floating_email"
                id="floating_email"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
                v-model="email"
                required
              />
              <label
                for="floating_email"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Mailadress</label
              >
            </div>
            <div class="relative z-0 w-full mb-6 group">
              <input
                type="password"
                name="floating_password"
                id="floating_password"
                class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer"
                placeholder=" "
                v-model="password"
                required
              />
              <label
                for="floating_password"
                class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-focus:dark:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >Lösenord</label
              >
            </div>
          </div>
          <div class="flex justify-between">
            <button
              type="button"
              @click="$router.push('/forgot-password')"
              class="text-sm dark:text-white hover:text-primary text-gray-700"
            >
              Glömt lösenord?
            </button>
          </div>
          <button
            type="submit"
            class="text-white bg-blue-700 w-full hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Logga in
          </button>
          <p v-if="errorMessage" class="text-red-500">{{ errorMessage }}</p>
        </div>
      </form>
    </div>
    <div class="bg-wave bg-white w-2/5 lg:block hidden"></div>
  </div>
</template>

<script>
import { useUserStore } from "@/store/user.js";
import { msStore } from "@/store/index";
import { PublicClientApplication } from "@azure/msal-browser";
import { uploadUserImage } from "@/api/index.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      account: undefined,
    };
  },
  async created() {
    this.$msalInstance = new PublicClientApplication(msStore().msalConfig);
    await this.$msalInstance.initialize();

    this.$emitter.on("login", (account) => {
      this.account = account;
    });
  },
  mounted() {
    const accounts = this.$msalInstance.getAllAccounts();
    if (accounts.length == 0) {
      return;
    }
    this.account = accounts[0];
    this.$emitter.emit("login", this.account);
  },
  methods: {
    async loginUser() {
      try {
        const { success, message } = await useUserStore().login(
          this.email,
          this.password,
          false
        );
        if (!success) {
          this.errorMessage = message;
        } else {
          this.$router.push("/");
        }
      } catch (error) {
        console.error("An error occurred during login:", error);
        this.errorMessage = "An unexpected error occurred.";
      }
    },
    async signIn() {
      try {
        await this.$msalInstance.loginPopup({
          loginHint: "fornamn.efternamn@stendahls.se",
        });
        const myAccounts = this.$msalInstance.getAllAccounts();
        this.account = myAccounts[0];
        this.$emitter.emit("login", this.account);
        await this.$msalInstance
          .acquireTokenSilent({
            scopes: ["User.Read"],
            account: this.account,
          })
          .then(async (accessTokenResponse) => {
            const accessToken = accessTokenResponse.accessToken;

            // Get user's profile photo using Microsoft Graph API
            const graphResponse = await fetch(
              "https://graph.microsoft.com/v1.0/me/photo/$value",
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            // this.logLastLogin(accessToken);

            // Continue with your authentication process
            const { success, message } = await useUserStore().login(
              Object(this.account),
              null,
              true
            );
            if (!success) {
              this.errorMessage = message;
            } else {
              const formData = new FormData();
              if (!graphResponse.ok) {
                console.log("Failed to fetch profile photo");
              } else {
                const profilePhotoBlob = await graphResponse.blob();
                formData.append("email", this.account.username);
                formData.append(
                  "profile_image",
                  profilePhotoBlob,
                  `profile-${this.account.username}.jpg`
                );
                await uploadUserImage(formData);
              }
              this.$router.push("/");
            }
          });
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    },
    logLastLogin(accessToken) {
      // Decode the token to extract information
      const decodedToken = this.parseJwt(accessToken);

      // Extract relevant information, such as the expiration time
      const expirationTime = new Date(decodedToken.exp * 1000);

      // Log the last login time
      console.log("Last login time:", expirationTime);
      console.log("Decoded:", decodedToken);
    },
    // Helper function to parse JWT tokens
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style>
/* custom pattern https://superdesigner.co/tools/css-backgrounds */
.bg-wave {
  background: radial-gradient(
      circle at top left,
      transparent 9%,
      #1d4ed8 10%,
      #1d4ed8 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at bottom left,
      transparent 9%,
      #1d4ed8 10%,
      #1d4ed8 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at top right,
      transparent 9%,
      #1d4ed8 10%,
      #1d4ed8 15%,
      transparent 16%
    ),
    radial-gradient(
      circle at bottom right,
      transparent 9%,
      #1d4ed8 10%,
      #1d4ed8 15%,
      transparent 16%
    ),
    radial-gradient(circle, transparent 25%, #ffffff 26%),
    linear-gradient(
      45deg,
      transparent 46%,
      #1d4ed8 47%,
      #1d4ed8 52%,
      transparent 53%
    ),
    linear-gradient(
      135deg,
      transparent 46%,
      #1d4ed8 47%,
      #1d4ed8 52%,
      transparent 53%
    );
  background-size: 6em 6em;
  background-color: #ffffff;
  opacity: 1;
}
</style>
