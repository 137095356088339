<template>
  <aside
    class="fixed top-0 left-0 z-40 w-64 h-dvh lg:h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
    aria-label="Sidenav"
    v-if="!$route.meta.hideNav"
    id="drawer-navigation"
  >
    <div class="overflow-y-auto py-5 px-3 h-full bg-white dark:bg-gray-800">
      <ul class="space-y-2">
        <li>
          <router-link
            to="/"
            @click="hideSidebar"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
          >
            <Icon class="w-5 h-5" icon="ph-presentation-chart" />
            <span class="ml-3">Dashboard</span>
          </router-link>
        </li>
        <li>
          <button
            type="button"
            class="flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
            aria-controls="dropdown1"
            data-collapse-toggle="dropdown1"
          >
            <Icon class="w-5 h-5" icon="ph-student" />
            <span class="flex-1 ml-3 text-left whitespace-nowrap"
              >Utbildningar/Policys</span
            >
            <Icon class="w-5 h-5" icon="ph-caret-down" />
          </button>
          <ul id="dropdown1" class="py-2 space-y-2">
            <li v-for="training in allActiveTrainings" :key="training._id">
              <router-link
                :to="`/utbildningar/${training.short_url}`"
                @click="hideSidebar"
                class="flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700"
              >
                {{ training.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
      <ul
        v-if="isAdmin"
        class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700"
      >
        <p
          class="flex items-center text-base font-bold text-gray-900 mb-4 dark:text-white"
        >
          <Icon class="mr-2 w-5 h-5" icon="ph-vault" />Adminpanel
        </p>
        <li>
          <router-link
            to="/admin/utbildningar"
            @click="hideSidebar"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-700 group"
          >
            <Icon class="w-5 h-5" icon="ph-books" />
            <span class="ml-3">Alla utbildningar</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/admin/anvandare"
            @click="hideSidebar"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-300 group"
          >
            <Icon class="w-5 h-5" icon="ph-users-three" />
            <span class="ml-3">Alla användare</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/admin/statistik"
            @click="hideSidebar"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-300 group"
          >
            <Icon class="w-5 h-5" icon="ph-chart-bar" />
            <span class="ml-3">Statistik</span>
          </router-link>
        </li>
      </ul>
      <ul
        class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700"
      >
        <li>
          <a
            href="https://iodigitalcom.slack.com/archives/C071PJUUQ3H"
            target="_blank"
            class="flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-gray-300 group"
          >
            <Icon class="w-5 h-5" icon="ph-lifebuoy" />
            <div class="flex justify-between w-full items-center">
              <span class="ml-3">Support</span>
              <Icon class="w-5 h-5" icon="ph-arrow-square-out" />
            </div>
          </a>
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from "flowbite";
import { useSidebarStore } from "@/store";
import { useUserStore } from "@/store/user.js";
import { useTrainingsStore } from "@/store/trainings.js";

export default {
  data() {
    return {};
  },
  components: {
    Icon,
  },
  computed: {
    sidebar() {
      return useSidebarStore().sidebar;
    },
    sidebarVisible() {
      return useSidebarStore().sidebarVisible;
    },
    isAdmin() {
      return useUserStore().user.global_admin;
    },
    allActiveTrainings() {
      return useTrainingsStore().allActiveTrainings;
    },
  },
  methods: {
    toggleSidebar() {
      this.sidebar.toggle();
      useSidebarStore().setSidebarState(this.sidebar().isVisible());
    },
    hideSidebar() {
      this.sidebar.hide();
      useSidebarStore().setSidebarState(false);
    },
    async fetchAllTrainings() {
      try {
        await useTrainingsStore().getAll();
      } catch (error) {
        console.error("Error fetching trainings:", error);
      }
    },
  },
  mounted() {
    initFlowbite();
    this.fetchAllTrainings();
  },
};
</script>
