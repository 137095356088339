<template>
  <section v-if="allUserStats" class="pb-10 dark:bg-gray-900">
    <div
      class="grid max-w-screen-xl px-0 pt-0 py-5 mx-auto lg:gap-8 xl:gap-0 lg:py-12 grid-cols-1 lg:grid-cols-12"
    >
      <div class="flex mb-5 col-span-5 lg:hidden">
        <img
          :src="[
            trainingData.image_url
              ? trainingData.image_url
              : 'https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
          ]"
          class="rounded-b-lg"
          alt="mockup"
        />
      </div>
      <div
        v-if="trainingData"
        class="px-4 w-full place-self-center col-span-full lg:col-span-7"
      >
        <div class="flex justify-between">
          <div
            v-if="trainingIsCompleted"
            class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white"
            role="alert"
          >
            <span
              v-if="trainingIsApproved"
              class="text-xs bg-green-600 rounded-full text-white px-4 py-1.5 mr-3"
            >
              {{ trainingData.accept_only ? "Godkänt" : "Godkänd" }}
            </span>
            <span
              v-else
              class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3"
              >Ej godkänd</span
            >
            <span class="text-sm font-medium">{{
              getAcceptedDate(trainingData)
            }}</span>
          </div>
          <div v-else />
          <span
            v-if="trainingData.mandatory"
            class="inline-flex items-center h-8 bg-yellow-100 text-yellow-800 text-xs font-medium px-3 py-2 rounded-full dark:bg-yellow-900 dark:text-yellow-300"
          >
            <Icon class="w-4 h-4 mr-2" icon="ph-alarm" />
            Obligatorisk
          </span>
        </div>
        <h1
          class="max-w-2xl mb-8 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white"
        >
          {{ trainingData.title }}
        </h1>
        <p
          class="max-w-2xl mb-4 font-semibold text-gray-500 lg:mb-8 md:text-xl lg:text-2xl dark:text-gray-400"
        >
          {{ trainingData.subtitle }}
        </p>
        <p
          class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"
        >
          {{ trainingData.description }}
        </p>
        <div class="flex flex-col lg:flex-row gap-5">
          <router-link
            :to="{
              name: 'TrainingSection',
              params: {
                training: this.trainingId,
                shorturl: `${firstSection}`,
              },
            }"
            class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            {{
              trainingData.accept_only
                ? "Läs igenom"
                : trainingIsCompleted
                ? "Kör igen!"
                : "Starta utbildningen"
            }}
            <Icon class="w-5 h-5 ml-2" icon="ph-arrow-right" />
          </router-link>
          <div class="flex justify-between grow">
            <router-link
              v-if="trainingIsApproved && !trainingData.accept_only"
              :to="{
                name: 'TrainingCertificate',
                params: { training: this.trainingId },
              }"
              class="inline-flex items-center justify-center px-3 py-2 lg:px-5 text-base font-medium text-center text-amber-600 border border-amber-600 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-amber-500 dark:border-amber-500 dark:hover:bg-amber-900/40 dark:focus:ring-gray-800"
            >
              Se ditt diplom 🤩
            </router-link>
            <router-link
              v-if="trainingIsCompleted && !trainingData.accept_only"
              :to="{
                name: 'TrainingResult',
                params: { training: this.trainingId },
              }"
              class="inline-flex items-center justify-center px-3 py-2 lg:px-5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              Alla dina försök 🙈
            </router-link>
          </div>
        </div>
      </div>
      <div class="hidden lg:mt-0 lg:col-span-5 lg:flex justify-end">
        <img
          :src="[
            trainingData.image_url
              ? trainingData.image_url
              : 'https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
          ]"
          class="rounded-lg object-cover"
          alt="mockup"
        />
      </div>
    </div>
    <div v-if="sections.length > 1" class="px-4">
      <div class="max-w-screen-xl mx-auto">
        <h2
          class="text-gray-900 dark:text-white text-2xl md:text-4xl font-extrabold mt-5 mb-10"
        >
          Sektioner
        </h2>
      </div>
      <div
        v-for="section in sections"
        :key="section"
        class="flex gap-5 bg-white dark:bg-gray-800 shadow-xl dark:border-gray-700 rounded-lg p-8 md:p-12 mt-8 lg:mt-0 mb-8 max-w-screen-xl mx-auto"
      >
        <img
          src="https://images.pexels.com/photos/886521/pexels-photo-886521.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
          class="hidden lg:block w-52 h-52 object-cover rounded-lg"
        />
        <div>
          <template v-for="(sub, index) in section.sub_sections" :key="index">
            <div
              v-if="getSectionType(sub.type)"
              :class="`${getSectionType(sub.type).bg_color} ${
                getSectionType(sub.type).color
              } dark:${getSectionType(sub.type).dark_bg} dark:${
                getSectionType(sub.type).dark_color
              }`"
              class="text-xs font-medium inline-flex items-center mr-2 px-2.5 py-0.5 rounded-md mb-4"
            >
              <Icon class="mr-1" :icon="getSectionType(sub.type).icon" />
              {{ getSectionType(sub.type).title }}
            </div>
          </template>
          <h1
            class="text-gray-900 dark:text-white text-xl md:text-3xl font-extrabold mb-2"
          >
            {{ section.title }}
          </h1>
          <p class="text-lg font-normal text-gray-500 dark:text-gray-400 mb-6">
            {{ section.description }}
          </p>
          <router-link
            :to="{
              name: 'TrainingSection',
              params: {
                training: this.trainingId,
                shorturl: section.short_url,
              },
              props: { shorturl: section.training },
            }"
            class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
          >
            Gå till sektion
            <Icon class="w-5 h-5 ml-2" icon="ph-arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { Icon } from "@iconify/vue";
import {
  getTraining,
  fetchSectionsByTraining,
  fetchUserResponsesForUser,
} from "@/api/index.js";
import { useUserReponse } from "@/store/responses.js";
import { useUserStore } from "@/store/user";
import { SectionTypes } from "@/constants/sectionTypes";

export default {
  data() {
    return {
      user: null,
      trainingId: this.$route.params.id,
      trainingData: {},
      sections: [],
      sectionTypes: SectionTypes,
      numberOfAllQuestions: 0,
      allUserStats: [],
      trainingIsCompleted: false,
      trainingIsApproved: false,
      bestPercentage: 0,
    };
  },
  components: {
    Icon,
  },
  computed: {
    firstSection() {
      return Object(this.sections[0]).short_url;
    },
    amountOfTotalQuestions() {
      return useUserReponse().activeTraining.amountOfQuestions;
    },
  },
  mounted() {
    this.loadUser();
    this.fetchTraining();
    setTimeout(() => {
      this.getAllStats();
    }, 100);
  },
  watch: {
    "$route.params.id"(newId) {
      this.trainingId = newId;
      this.loadUser();
      this.fetchTraining();
      setTimeout(() => {
        this.getAllStats();
      }, 100);
    },
    // user: {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue && newValue._id) {
    //       this.getAllStats();
    //     }
    //   }
    // },
    // allUserStats: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.isTrainingCompleted();
    //       this.isTrainingApproved();
    //     }
    //   }
    // }
  },
  methods: {
    async loadUser() {
      try {
        const { success, user } = await useUserStore().getUser(); // Call the getUser action from the store
        if (success) {
          this.user = user;
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async fetchTraining() {
      try {
        const response = await getTraining(this.trainingId);
        this.trainingData = response;
        this.fetchCourses(response._id);
      } catch (error) {
        console.error("Error fetching training:", error);
        this.$router.push("/admin/utbildningar/");
      }
    },
    async fetchCourses(id) {
      try {
        this.sections = await fetchSectionsByTraining(id);
        this.totalQuestions();
        useUserReponse().activeTraining.trainingId = this.trainingId;
        useUserReponse().activeTraining.amountOfQuestions =
          this.numberOfAllQuestions;
      } catch (error) {
        console.error("Error fetching sections:", error);
      }
    },
    async getAllStats() {
      try {
        this.allUserStats = await fetchUserResponsesForUser(this.user._id);
        this.isTrainingCompleted();
        this.isTrainingApproved();
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    },
    getSectionType(type) {
      return this.sectionTypes.find((sectionType) => sectionType.id === type);
    },
    totalQuestions() {
      this.numberOfAllQuestions = 0;
      this.sections.forEach((s) => {
        s.sub_sections.forEach((ss) => {
          if (ss.type === "questions") {
            ss.data.questions.forEach(() => {
              this.numberOfAllQuestions++;
            });
          }
        });
      });
    },
    isTrainingCompleted() {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === this.trainingData._id
      );
      this.trainingIsCompleted = false;
      return matchingEntries.some((entry) => {
        if (entry.responses.length !== 0) {
          return (this.trainingIsCompleted = true);
        } else {
          return (this.trainingIsCompleted = entry.accepted);
        }
      });
    },
    isTrainingApproved() {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === this.trainingData._id
      );
      this.trainingIsApproved = false;
      return matchingEntries.some((entry) => {
        if (entry.responses.length !== 0) {
          let maxCorrect = 0;
          let totalQuestions = 0;
          entry.responses.forEach((response) => {
            totalQuestions++;
            if (response.correct[0]) {
              maxCorrect++;
            }
          });
          const bestPercentage =
            totalQuestions === 0 ? 0 : (maxCorrect / totalQuestions) * 100;
          this.bestPercentage = bestPercentage;
          return (this.trainingIsApproved =
            bestPercentage >= this.trainingData.approved_threshold);
        } else {
          return (this.trainingIsApproved = entry.accepted);
        }
      });
    },
    getAcceptedDate(training) {
      const matchingEntries = this.allUserStats.filter(
        (entry) => entry.training_section === training._id
      );
      let latestTimestamp = null;
      matchingEntries.forEach((entry) => {
        const entryTimestamp = new Date(entry.timestamp);
        if (!latestTimestamp || entryTimestamp > latestTimestamp) {
          latestTimestamp = entryTimestamp;
        }
      });
      if (latestTimestamp) {
        const date = new Date(latestTimestamp);
        const formattedDay = date.getDate();
        const formattedMonth = new Intl.DateTimeFormat("sv-SE", {
          month: "long",
        }).format(date);
        const currentYear = new Date().getFullYear();
        const formattedYear =
          date.getFullYear() === currentYear ? "" : ` ${date.getFullYear()}`;
        return `${formattedDay} ${formattedMonth}${formattedYear}`;
      }
      return "";
    },
  },
};
</script>
