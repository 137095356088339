<template>
  <div>
    <section
      v-for="(subSection, index) in sectionData.sub_sections"
      :key="index"
      class="pb-0 md:pt-32 last:pb-28"
    >
      <template v-if="subSection.type === 'video'">
        <VideoSection
          :sectionId="sectionData._id"
          :subSectionId="subSection._id"
          :videoData="subSection.data"
          :isEditable="isEditable"
        />
      </template>
      <template v-else-if="subSection.type === 'image'">
        <ImageSection
          :sectionId="sectionData._id"
          :subSectionId="subSection._id"
          :imageData="subSection.data"
          :isEditable="isEditable"
        />
      </template>
      <template v-else-if="subSection.type === 'texts'">
        <TextsSection
          :sectionId="sectionData._id"
          :subSectionId="subSection._id"
          :textData="subSection.data"
          :isEditable="isEditable"
        />
      </template>
      <template v-else-if="subSection.type === 'questions'">
        <QuestionsSection
          ref="questionRef"
          :sectionId="sectionData._id"
          :trainingId="sectionData.training"
          :subSectionId="subSection._id"
          :questionData="subSection.data"
          :isEditable="isEditable"
          @send-answers="setAnswered"
          @send-submit="(val) => this.$emit('sendSubmit', val)"
        />
      </template>
      <template v-else-if="subSection.type === 'pdf'">
        <PDFSection
          :sectionId="sectionData._id"
          :trainingId="sectionData.training"
          :subSectionId="subSection._id"
          :pdfData="subSection.data"
          :sectionTitle="sectionData.title"
          :isEditable="isEditable"
        />
      </template>
    </section>
    <section v-if="acceptOnly">
      <AcceptSection :trainingId="sectionData.training" />
    </section>
  </div>
</template>

<script>
import VideoSection from "./VideoSection";
import ImageSection from "./ImageSection.vue";
import TextsSection from "./TextsSection.vue";
import QuestionsSection from "./QuestionsSection.vue";
import PDFSection from "./PDFSection.vue";
import AcceptSection from "./AcceptSection.vue";
import { useUserReponse } from "@/store/responses.js";

export default {
  emits: ["sendDone", "sendSubmit"],
  props: {
    sectionData: Object,
    isEditable: Boolean,
    acceptOnly: Boolean,
    amountOfQuestions: Number,
  },
  components: {
    VideoSection,
    ImageSection,
    TextsSection,
    QuestionsSection,
    PDFSection,
    AcceptSection,
  },
  data() {
    return {
      answers: [],
      isAnswered: false,
    };
  },
  computed: {
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    // allQuestionsAnswered() {
    //   if (this.isAnswered) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
  },
  watch: {
    // allQuestionsAnswered: {
    //   handler: "isDone",
    //   immediate: true,
    // },
    sectionData: {
      immediate: true,
      handler() {
        if (Object.keys(this.sectionData).length !== 0) {
          this.checkIfDone();
        }
        // Clear Stored Response if user change training
        let val = useUserReponse().userResponse.response;
        if (val.length !== 0) {
          setTimeout(() => {
            if (val[0].trainingId !== this.sectionData.training) {
              useUserReponse().userResponse.response = [];
            }
          }, 500);
        }
      },
    },
  },
  methods: {
    setAnswered(newData) {
      this.answers.push(newData);
      useUserReponse().userResponse.response = this.answers;
      useUserReponse().userResponse.trainingId = this.sectionData.training;
    },
    isDone(val) {
      this.$emit("sendDone", val);
    },
    checkIfDone() {
      // Collect question IDs
      const questionIds = this.sectionData.sub_sections
        .filter((sub) => sub.type === "questions")
        .flatMap((sub) => sub.data.questions.map((q) => q._id));

      // Collect collected response IDs
      const responseIds = this.collectedResponse.map((cr) => cr.questionId);

      // Ensure all question IDs are in the collected responses
      const allQuestionsAnswered = questionIds.every((id) =>
        responseIds.includes(id)
      );
      this.isAnswered = allQuestionsAnswered;
      this.isDone(this.isAnswered);
    },
    submitAnswers() {
      const questionRef = this.$refs.questionRef;
      const hasUnanswered = questionRef.some((qs) => !qs.isAnswered);

      if (hasUnanswered) {
        console.log("Please answer all questions before submitting."); //TODO: Add popup
      } else {
        this.isDone(true);
        questionRef.forEach((qs) => {
          qs.submitAnswer();
        });
      }
    },
  },
};
</script>
