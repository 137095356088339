<template>
  <!-- QUESTION SECTION -->
  <div class="flex flex-col py-8 px-4 mx-auto max-w-screen-xl">
    <div
      v-for="(q, index) in isEditable
        ? editedQuestionsSection.questions
        : questionData.questions"
    >
      <div
        v-if="isEditable"
        :class="
          ({ 'justify-start': q.layout == 'Layout 1' || 'Layout 3' },
          { 'justify-center': q.layout == 'Layout 2' })
        "
        class="flex flex-row flex-none items-center group gap-2 mb-5"
      >
        <!-- LAYOUT DROPDOWN -->
        <div
          :class="{ 'grid-cols-2 gap-10': q.layout == 'Layout 2' }"
          class="grid"
        >
          <div v-if="q.layout == 'Layout 2'"></div>
          <button
            :id="`dropdown-button-question${index}`"
            @click="
              toggleSectionDropdown(`question${index}`, q.layout == 'Layout 2')
            "
            class="justify-between z-10 inline-flex items-center bg-gray-50 border border-gray-300 text-gray-900 text-center text-sm rounded-lg border-gray-100 dark:border-gray-700 border-2 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="button"
          >
            {{ q.layout }}
            <svg
              class="w-2.5 h-2.5 ms-2.5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          <div
            :id="`dropdown-layout-question${index}`"
            class="hidden mt-1 z-20 bg-white rounded-lg border-gray-200 shadow-lg border-y dark:bg-gray-800 dark:border-gray-600"
          >
            <div
              class="w-full px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-2 md:grid-cols-3 md:px-6"
            >
              <h3
                class="mb-5 text-lg font-medium text-gray-900 dark:text-white"
              >
                Välj layoutstyp
              </h3>
              <ul class="grid gap-6 md:grid-cols-3">
                <li class="w-52">
                  <input
                    @change="
                      questionEdited();
                      hideSectionDropdown(`question${index}`);
                    "
                    type="radio"
                    :id="`question${index}-layout1-option`"
                    name="layouttype"
                    value="Layout 1"
                    class="hidden peer"
                    v-model="q.layout"
                    required=""
                  />
                  <label
                    :for="`question${index}-layout1-option`"
                    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <div class="block w-full">
                      <div
                        class="flex flex-col w-fit border m-auto p-5 mb-2 w-32 h-32 justify-center"
                      >
                        <Icon class="w-14 h-14" icon="ic-outline-short-text" />
                        <Icon class="w-14 h-14" icon="basil-layout-outline" />
                      </div>
                      <div class="w-full text-lg font-semibold">Layout 1</div>
                      <div class="w-full text-sm">Fråga och svar</div>
                    </div>
                  </label>
                </li>
                <li class="w-52">
                  <input
                    @change="
                      questionEdited();
                      hideSectionDropdown(`question${index}`);
                    "
                    type="radio"
                    :id="`question${index}-layout2-option`"
                    name="layouttype"
                    value="Layout 2"
                    class="hidden peer"
                    v-model="q.layout"
                    required=""
                  />
                  <label
                    :for="`question${index}-layout2-option`"
                    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <div class="block w-full">
                      <div
                        class="flex w-fit border m-auto p-5 mb-2 w-32 h-32 jusitfy-center"
                      >
                        <Icon class="w-10 h-10" icon="ph-image" />
                        <Icon class="w-10 h-10" icon="basil-layout-outline" />
                      </div>
                      <div class="w-full text-lg font-semibold">Layout 2</div>
                      <div class="w-full text-sm">
                        Bild åt vänster, frågan åt höger
                      </div>
                    </div>
                  </label>
                </li>
                <li class="w-52">
                  <input
                    @change="
                      questionEdited();
                      hideSectionDropdown(`question${index}`);
                    "
                    type="radio"
                    :id="`question${index}-layout3-option`"
                    name="layouttype"
                    value="Layout 3"
                    class="hidden peer"
                    v-model="q.layout"
                    required=""
                  />
                  <label
                    :for="`question${index}-layout3-option`"
                    class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                  >
                    <div class="block w-full">
                      <div
                        class="flex w-fit border m-auto p-5 mb-2 w-32 h-32 jusitfy-center"
                      >
                        <Icon class="w-10 h-10" icon="basil-layout-outline" />
                        <Icon class="w-10 h-10" icon="ph-image" />
                      </div>
                      <div class="w-full text-lg font-semibold">Layout 3</div>
                      <div class="w-full text-sm">Omvänd Layout 2</div>
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button
          type="button"
          @click="deleteQuestion(index)"
          class="text-blue-700 border border-blue-700 hover:border-red-700 hover:bg-red-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
        >
          <Icon class="w-5 h-5" icon="ph-trash" />
          <span class="sr-only">Delete</span>
        </button>
      </div>
      <!-- END DROPDOWN -->
      <!-- LAYOUT 1 -->
      <div v-if="q.layout == 'Layout 1'">
        <p class="pb-5 text-gray-500 font-bold">Fråga {{ index + 1 }}</p>
        <h3
          :contenteditable="makeEditable"
          @click="makeEditableFunc(isEditable)"
          @focusout="makeEditableFunc(false)"
          @blur="
            (e) => {
              q.question = e.currentTarget.textContent;
              questionEdited();
            }
          "
          v-html="q.question || 'Skriv en fråga'"
          :class="[
            isEditable
              ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
              : '',
          ]"
          class="mb-10 text-xl md:text-4xl font-medium text-gray-900 dark:text-white"
        ></h3>
        <ul class="grid w-full gap-6 md:grid-cols-2">
          <li v-if="!isEditable" v-for="(a, i) in q.answers">
            <input
              :disabled="correctAnswers.length !== 0"
              type="radio"
              :id="`section${subSectionId}-q${index}-a${i}`"
              :name="`section${subSectionId}-q${index}`"
              :value="i"
              :checked="
                alreadyAnswered.length !== 0
                  ? checkAnswer(index, i, a)
                  : undefined
              "
              class="hidden peer"
              v-model="userAnswered[index]"
              required
            />
            <label
              :for="`section${subSectionId}-q${index}-a${i}`"
              :class="{
                'peer-checked:bg-green-400 peer-checked:border-green-600 peer-checked:text-white':
                  correctAnswers[index] && correctAnswers[index].isCorrect,
                'peer-checked:bg-red-400 peer-checked:border-red-600 peer-checked:text-white':
                  correctAnswers[index] && !correctAnswers[index].isCorrect,
              }"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div class="block">
                <div class="w-full text-lg font-semibold">
                  {{ a.text }}
                </div>
              </div>
            </label>
          </li>
          <li v-if="isEditable" v-for="(a, i) in q.answers">
            <div
              :for="`section${subSectionId}-q${index}-a${i}`"
              class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
            >
              <div
                :contenteditable="makeEditable"
                @click="makeEditableFunc(isEditable)"
                @focusout="makeEditableFunc(false)"
                @blur="
                  (e) => {
                    a.text = e.currentTarget.textContent;
                    questionEdited();
                  }
                "
                :class="[
                  isEditable
                    ? `rounded-lg focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
                    : '',
                ]"
                class="w-full text-lg font-semibold"
              >
                {{ a.text }}
              </div>
              <div class="flex flex-row gap-1">
                <button
                  type="button"
                  @click="correctAnswer(index, i, a)"
                  :class="{
                    'bg-green-500 border-green-500 text-white':
                      q.correct_answers.includes(i),
                  }"
                  class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <Icon class="w-5 h-5" icon="ph-check" />
                </button>
                <button
                  type="button"
                  @click="deleteAnswer(index, i, a)"
                  v-if="q.answers.length >= 3"
                  class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <Icon class="w-5 h-5" icon="ph-trash" />
                </button>
              </div>
            </div>
          </li>
        </ul>
        <div v-if="isEditable" class="flex w-full justify-center my-10">
          <button
            @click="addMoreAnswers(index, q)"
            class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Lägg till svar
          </button>
        </div>
      </div>
      <!-- LAYOUT 2 -->
      <div v-if="q.layout == 'Layout 2'" class="flex flex-col">
        <div class="flex flex-wrap md:flex-nowrap gap-10">
          <div class="mx-auto h-auto w-full lg:max-w-xl">
            <!-- <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="q.media_url"
              @input="checkImageUrl(index)" @blur="questionEdited();"
              class="mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="URL till bilden" required> -->
            <PexelsComponent
              :isEditable="isEditable"
              :subSectionId="subSectionId"
              :editedData="q"
              :editedDataIndex="index + 1"
              @is-edited="setEdited"
            />
            <div v-if="!q.media_url && !isValidUrl">
              {{ isEditable ? "Ingen bild" : "" }}
            </div>
            <img
              v-else
              :src="q.media_url"
              class="mx-auto h-auto w-full lg:max-w-xl rounded-lg shadow-xl object-fit"
            />
          </div>
          <div class="w-full">
            <p class="pb-5 text-gray-500 font-bold">Fråga {{ index + 1 }}</p>
            <h3
              :contenteditable="makeEditable"
              @click="makeEditableFunc(isEditable)"
              @focusout="makeEditableFunc(false)"
              @blur="
                (e) => {
                  q.question = e.currentTarget.textContent;
                  questionEdited();
                }
              "
              v-html="q.question || 'Skriv en fråga'"
              :class="[
                isEditable
                  ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
                  : '',
              ]"
              class="mb-10 text-xl md:text-4xl font-medium text-gray-900 dark:text-white"
            ></h3>
            <ul class="grid w-full gap-6 md:grid-cols-2">
              <li v-if="!isEditable" v-for="(a, i) in q.answers">
                <input
                  :disabled="correctAnswers.length !== 0"
                  type="radio"
                  :id="`section${subSectionId}-q${index}-a${i}`"
                  :name="`section${subSectionId}-q${index}`"
                  :value="i"
                  :checked="
                    alreadyAnswered.length !== 0
                      ? checkAnswer(index, i, a)
                      : undefined
                  "
                  class="hidden peer"
                  v-model="userAnswered[index]"
                  required
                />
                <label
                  :for="`section${subSectionId}-q${index}-a${i}`"
                  :class="{
                    'peer-checked:bg-green-400 peer-checked:border-green-600 peer-checked:text-white':
                      correctAnswers[index] && correctAnswers[index].isCorrect,
                    'peer-checked:bg-red-400 peer-checked:border-red-600 peer-checked:text-white':
                      correctAnswers[index] && !correctAnswers[index].isCorrect,
                  }"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block">
                    <div class="w-full text-lg font-semibold">
                      {{ a.text }}
                    </div>
                  </div>
                </label>
              </li>
              <li v-if="isEditable" v-for="(a, i) in q.answers">
                <div
                  :for="`section${subSectionId}-q${index}-a${i}`"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div
                    :contenteditable="makeEditable"
                    @click="makeEditableFunc(isEditable)"
                    @focusout="makeEditableFunc(false)"
                    @blur="
                      (e) => {
                        a.text = e.currentTarget.textContent;
                        questionEdited();
                      }
                    "
                    :class="[
                      isEditable
                        ? `rounded-lg focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
                        : '',
                    ]"
                    class="w-full text-lg font-semibold"
                  >
                    {{ a.text }}
                  </div>
                  <div class="flex flex-row gap-1">
                    <button
                      type="button"
                      @click="correctAnswer(index, i, a)"
                      :class="{
                        'bg-green-500 border-green-500 text-white':
                          q.correct_answers.includes(i),
                      }"
                      class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      <Icon class="w-5 h-5" icon="ph-check" />
                    </button>
                    <button
                      type="button"
                      @click="deleteAnswer(index, i, a)"
                      v-if="q.answers.length >= 3"
                      class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      <Icon class="w-5 h-5" icon="ph-trash" />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="isEditable" class="flex w-full justify-center my-10">
          <button
            @click="addMoreAnswers(index, q)"
            class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Lägg till svar
          </button>
        </div>
      </div>
      <!-- LAYOUT 3 -->
      <div v-if="q.layout == 'Layout 3'" class="flex flex-col">
        <div class="flex flex-wrap md:flex-nowrap gap-10">
          <div class="w-full">
            <p class="pb-5 text-gray-500 font-bold">Fråga {{ index + 1 }}</p>
            <h3
              :contenteditable="makeEditable"
              @click="makeEditableFunc(isEditable)"
              @focusout="makeEditableFunc(false)"
              @blur="
                (e) => {
                  q.question = e.currentTarget.textContent;
                  questionEdited();
                }
              "
              v-html="q.question || 'Skriv en fråga'"
              :class="[
                isEditable
                  ? `rounded-lg hover:ring-2 hover:ring-blue-200 focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
                  : '',
              ]"
              class="mb-10 text-xl md:text-4xl font-medium text-gray-900 dark:text-white"
            ></h3>
            <ul class="grid w-full gap-6 md:grid-cols-2">
              <li v-if="!isEditable" v-for="(a, i) in q.answers">
                <input
                  :disabled="correctAnswers.length !== 0"
                  type="radio"
                  :id="`section${subSectionId}-q${index}-a${i}`"
                  :name="`section${subSectionId}-q${index}`"
                  :value="i"
                  :checked="
                    alreadyAnswered.length !== 0
                      ? checkAnswer(index, i, a)
                      : undefined
                  "
                  class="hidden peer"
                  v-model="userAnswered[index]"
                  required
                />
                <label
                  :for="`section${subSectionId}-q${index}-a${i}`"
                  :class="{
                    'peer-checked:bg-green-400 peer-checked:border-green-600 peer-checked:text-white':
                      correctAnswers[index] && correctAnswers[index].isCorrect,
                    'peer-checked:bg-red-400 peer-checked:border-red-600 peer-checked:text-white':
                      correctAnswers[index] && !correctAnswers[index].isCorrect,
                  }"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div class="block">
                    <div class="w-full text-lg font-semibold">{{ a.text }}</div>
                  </div>
                </label>
              </li>
              <li v-if="isEditable" v-for="(a, i) in q.answers">
                <div
                  :for="`section${subSectionId}-q${index}-a${i}`"
                  class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  <div
                    :contenteditable="makeEditable"
                    @click="makeEditableFunc(isEditable)"
                    @focusout="makeEditableFunc(false)"
                    @blur="
                      (e) => {
                        a.text = e.currentTarget.textContent;
                        questionEdited();
                      }
                    "
                    :class="[
                      isEditable
                        ? `rounded-lg focus:outline-none focus:bg-transparent focus:ring-2 focus:ring-blue-500 focus:ring-offset-8`
                        : '',
                    ]"
                    class="w-full text-lg font-semibold"
                  >
                    {{ a.text }}
                  </div>
                  <div class="flex flex-row gap-1">
                    <button
                      type="button"
                      @click="correctAnswer(index, i, a)"
                      :class="{
                        'bg-green-500 border-green-500 text-white':
                          q.correct_answers.includes(i),
                      }"
                      class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      <Icon class="w-5 h-5" icon="ph-check" />
                    </button>
                    <button
                      type="button"
                      @click="deleteAnswer(index, i, a)"
                      v-if="q.answers.length >= 3"
                      class="text-gray-900 border border-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-2.5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    >
                      <Icon class="w-5 h-5" icon="ph-trash" />
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="mx-auto h-auto w-full lg:max-w-xl">
            <!-- <input v-if="isEditable" type="url" name="mediaurl" id="mediaurl" v-model="q.media_url"
              @input="checkImageUrl(index)" @blur="questionEdited();"
              class="mb-3 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder="URL till bilden" required> -->
            <PexelsComponent
              :isEditable="isEditable"
              :subSectionId="subSectionId"
              :editedData="q"
              :editedDataIndex="index + 1"
              @is-edited="setEdited"
            />
            <div v-if="!q.media_url && !isValidUrl">
              {{ isEditable ? "Ingen bild" : "" }}
            </div>
            <img
              v-else
              :src="q.media_url"
              class="mx-auto h-auto w-full lg:max-w-xl rounded-lg shadow-xl object-fit"
            />
          </div>
        </div>
        <div v-if="isEditable" class="flex w-full justify-center my-10">
          <button
            @click="addMoreAnswers(index, q)"
            class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            Lägg till svar
          </button>
        </div>
      </div>
      <!-- HR -->
      <hr
        v-if="
          isEditable
            ? index !== editedQuestionsSection.questions.length - 1
            : index !== questionData.questions.length - 1
        "
        class="w-48 h-1 mx-auto my-10 bg-gray-100 border-0 rounded md:my-20 dark:bg-gray-700"
      />
    </div>
  </div>
  <div v-if="isEditable" class="flex w-full justify-center mt-10">
    <button
      @click="addDefaultQuestion"
      class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
    >
      Lägg till ny fråga
    </button>
  </div>
  <!-- END QUESTIONS -->
  <div v-if="isAnswered"></div>
  <!-- <div
    v-if="!isEditable && isAnswered"
    class="flex flex-row gap-2 grow justify-center group mt-10"
  >
    <button
      v-if="!isSubmitted"
      @click="submitAnswer"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-green-700 focus:outline-none bg-white rounded-lg border border-green-700 hover:bg-green-50 hover:text-green-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button"
    >
      Rätta svaren
    </button>
  </div> -->
  <div
    v-if="isEdited"
    class="flex flex-row gap-2 grow justify-center group mt-10"
  >
    <button
      @click="resetData"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button"
    >
      Återställ
    </button>
    <button
      @click="updateQuestionSection"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button"
    >
      Spara
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite, Dropdown } from "flowbite";
import {
  fetchSubSection,
  editSubSection,
  saveUserResponse,
  getCorrectAnswers,
} from "@/api/index.js";
import { useUserStore } from "@/store/user.js";
import { useUserReponse } from "@/store/responses.js";
import PexelsComponent from "@/components/PexelsComponent.vue";

export default {
  emits: ["sendAnswers", "sendSubmit"],
  props: {
    sectionId: String,
    trainingId: String,
    subSectionId: String,
    questionData: Object,
    isEditable: Boolean,
  },
  data() {
    return {
      subSection: [],
      editedQuestionsSection: {
        questions: [],
      },
      isEdited: false,
      isValidUrl: false,
      makeEditable: false,
      dropSection: null,
      userAnswered: [],
      oldUserAnswered: [],
      correctAnswers: [],
      alreadyAnswered: [],
      isSubmitted: false,
      pexelSearch: "",
      imageLayout: "landscape",
      searchResults: [],
    };
  },
  components: {
    Icon,
    PexelsComponent,
  },
  mounted() {
    initFlowbite();
    this.getSubSection();
    if (this.isEditable) {
      this.questionDataToEdit();
      if (this.editedQuestionsSection.questions.length === 0) {
        this.addDefaultQuestion();
      }
    }
    this.alreadyAnswered = [];
    this.collectedResponse.forEach((element) => {
      if (element.subsectionId == this.subSectionId) {
        this.oldUserAnswered.push(element.response);
        this.alreadyAnswered.push(element.response);
      }
    });
    if (this.alreadyAnswered.length !== 0) {
      this.checkCorrectAnswers();
    }
  },
  watch: {
    subSectionId(newId) {
      this.alreadyAnswered = [];
      this.correctAnswers = [];
      this.collectedResponse.forEach((element) => {
        if (element.subsectionId == this.subSectionId) {
          this.oldUserAnswered.push(element.response);
          this.alreadyAnswered.push(element.response);
        }
      });
      if (this.alreadyAnswered.length !== 0) {
        this.checkCorrectAnswers();
      }
      this.userAnswered = [];
      this.isSubmitted = false;
    },
  },
  computed: {
    user() {
      return useUserStore().user;
    },
    collectedResponse() {
      return useUserReponse().userResponse.response;
    },
    isAnswered() {
      const totalQuestions = this.questionData.questions.length;
      if (this.userAnswered.length === totalQuestions) {
        if (this.userAnswered.includes(undefined)) {
          return false;
        } else {
          this.$emit("sendSubmit", true);
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    async getSubSection() {
      try {
        this.subSection = await fetchSubSection(
          this.sectionId,
          this.subSectionId
        );
      } catch (error) {
        console.log("Error fetching subsections", error);
      }
    },
    async updateQuestionSection() {
      try {
        await editSubSection(
          this.sectionId,
          this.subSectionId,
          this.editedQuestionsSection
        );
        this.isEdited = false;
        this.getSubSection();
      } catch (error) {
        console.error("Error updating subsection:", error);
      }
    },
    questionDataToEdit() {
      this.isEdited = false;
      this.editedQuestionsSection.questions = [];
      this.questionData.questions.forEach((q, i) => {
        this.editedQuestionsSection.questions[i] = q;
      });
    },
    questionEdited() {
      if (this.subSection.questions) {
        this.subSection.questions.forEach((q, i) => {
          this.editedQuestionsSection.questions[i] !== q
            ? (this.isEdited = true)
            : (this.isEdited = false);
        });
      } else {
        this.isEdited = true;
      }
    },
    resetData() {
      this.getSubSection();
      this.isEdited = false;
      this.editedQuestionsSection.questions = [];
      if (this.subSection.questions) {
        this.subSection.questions.forEach((q, i) => {
          this.editedQuestionsSection.questions[i] = q;
        });
      }
    },
    checkImageUrl(i) {
      const imageExtensionsRegex = /\.(jpeg|jpg|png)/i;
      this.isValidUrl = imageExtensionsRegex.test(
        this.editedQuestionsSection.questions[i].media_url
      );
    },
    makeEditableFunc(val) {
      // contenteditable = true makes the whole div clickable, which isnt good UX, this is a workaround
      this.makeEditable = val;
    },
    toggleSectionDropdown(section, l2) {
      const targetEl = document.getElementById(`dropdown-layout-${section}`);
      const triggerEl = document.getElementById(`dropdown-button-${section}`);
      const options = {
        placement: l2 ? "bottom" : "right",
      };
      const instanceOptions = {
        id: `dropdown-layout-${section}`,
        override: true,
      };
      this.dropSection = new Dropdown(
        targetEl,
        triggerEl,
        options,
        instanceOptions
      );
      this.dropSection.toggle();
    },
    hideSectionDropdown(section) {
      const targetEl = document.getElementById(`dropdown-layout-${section}`);
      const triggerEl = document.getElementById(`dropdown-button-${section}`);
      const instanceOptions = {
        id: `dropdown-layout-${section}`,
        override: true,
      };
      this.dropSection = new Dropdown(targetEl, triggerEl, instanceOptions);
      this.dropSection.hide();
    },
    addDefaultQuestion() {
      this.editedQuestionsSection.questions.push({
        layout: "Layout 1",
        question: "Skriv din fråga",
        answers: [{ text: "Svar 1" }, { text: "Svar 2" }],
        correct_answers: [],
      });
      this.questionEdited();
    },
    addMoreAnswers(q, i) {
      const k = i.answers.length;
      this.editedQuestionsSection.questions[q].answers.push({
        text: `Svar ${k + 1}`,
      });
      this.questionEdited();
    },
    updateQuestion(index, event) {
      this.editedQuestionsSection.questions[index] =
        event.currentTarget.textContent;
      this.questionEdited();
    },
    deleteQuestion(index) {
      this.editedQuestionsSection.questions.splice(index, 1);
      this.questionEdited();
    },
    correctAnswer(q, i, a) {
      const answers = this.editedQuestionsSection.questions[q].answers.length;
      const correct_answers =
        this.editedQuestionsSection.questions[q].correct_answers;
      const existingIndex = correct_answers.indexOf(i);

      if (answers <= 2) {
        correct_answers.splice(a, 1);
        correct_answers.push(i);
      } else {
        if (existingIndex !== -1) {
          correct_answers.splice(existingIndex, 1);
        } else {
          correct_answers.push(i);
        }
      }
      this.questionEdited();
    },
    deleteAnswer(q, i) {
      this.editedQuestionsSection.questions[q].answers.splice(i, 1);
      this.questionEdited();
    },
    checkCorrectness(userResponse, questionIndex) {
      const correctAnswers =
        this.questionData.questions[questionIndex].correct_answers;
      return correctAnswers.includes(userResponse);
    },
    checkAnswer(index, i, a) {
      return this.alreadyAnswered[index] === i
        ? { index, _id: a._id }
        : undefined;
    },
    submitAnswer() {
      this.isSubmitted = true;
      this.$emit("sendSubmit", false);
      const newData = this.userAnswered.map((response, index) => ({
        trainingId: this.trainingId,
        subsectionId: this.subSectionId,
        questionId: this.questionData.questions[index]._id,
        response: response,
        correct: this.checkCorrectness(response, index),
      }));
      newData.forEach((question) => {
        this.$emit("sendAnswers", question);
      });
      this.checkCorrectAnswers();
    },
    checkCorrectAnswers() {
      const res = useUserReponse().userResponse.response;
      this.questionData.questions.forEach((question, index) => {
        const foundItem = res.find((item) => item.questionId === question._id);
        if (foundItem) {
          this.correctAnswers[index] = {
            _id: question._id,
            isCorrect: foundItem.correct,
          };
        } else {
          this.correctAnswers[index] = { _id: question._id, isCorrect: false };
        }
      });
    },
    setEdited(val) {
      this.isEdited = val;
    },
  },
};
</script>
