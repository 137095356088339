<template>
  <form
    v-if="isEditable"
    @submit.prevent="togglePexelDropdown()"
    class="mb-3 w-full"
  >
    <label
      for="default-search"
      class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
      >Sök</label
    >
    <div class="relative">
      <div
        class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
      >
        <Icon
          class="w-5 h-5 text-gray-500 dark:text-gray-400"
          icon="ph-magnifying-glass"
        />
      </div>
      <input
        type="search"
        id="default-search"
        v-model="pexelSearch"
        class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        placeholder="Sök. Ex. Natur eller Toscana"
        required
      />
      <button
        :id="`pexelDropdownButton-${subSectionId}-${
          editedData._id ? editedData._id : ''
        }-${editedDataIndex ? editedDataIndex : ''}`"
        type="submit"
        class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Search
      </button>
      <div
        :id="`pexelDropdown-${subSectionId}-${
          editedData._id ? editedData._id : ''
        }-${editedDataIndex ? editedDataIndex : ''}`"
        class="z-10 hidden bg-white rounded-lg shadow-2xl w-full p-5 dark:bg-gray-700"
      >
        <div class="inline-flex rounded-md mb-2" role="group">
          <button
            type="button"
            @click="handleInput('landscape')"
            :class="[
              imageLayout == 'landscape'
                ? 'bg-primary-700 text-white hover:bg-primary-800'
                : 'bg-white',
            ]"
            class="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Landscape
          </button>
          <button
            type="button"
            @click="handleInput('portrait')"
            :class="[
              imageLayout == 'portrait'
                ? 'bg-primary-700 text-white hover:bg-primary-800'
                : 'bg-white',
            ]"
            class="px-4 py-2 text-sm font-medium text-gray-900 border-t border-b border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Porträtt
          </button>
          <button
            type="button"
            @click="handleInput('square')"
            :class="[
              imageLayout == 'square'
                ? 'bg-primary-700 text-white hover:bg-primary-800'
                : 'bg-white',
            ]"
            class="px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-e-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Kvadrat
          </button>
        </div>
        <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
          <div
            v-for="photo in searchResults"
            :key="photo.id"
            @click="selectPhoto(photo, editedData)"
          >
            <img
              :class="{
                'h-52': imageLayout == 'portrait',
                'h-40': imageLayout == 'square',
              }"
              class="h-28 w-full rounded-lg object-cover hover:cursor-pointer"
              :src="photo.src.large"
              alt="Pexels Photo"
            />
          </div>
        </div>
        <div class="flex w-full justify-center">
          <button
            v-if="nextResults"
            type="button"
            @click="handleNextInput()"
            class="mt-5 px-4 py-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
          >
            Ladda fler
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite, Dropdown } from "flowbite";
import axios from "axios";

export default {
  emits: ["isEdited"],
  props: {
    subSectionId: String,
    editedData: Object,
    editedDataIndex: Number,
    isEditable: Boolean,
    isEdited: Boolean,
  },
  data() {
    return {
      pexelSearch: "",
      imageLayout: "landscape",
      searchResults: [],
      nextResults: "",
    };
  },
  components: {
    Icon,
  },
  mounted() {
    initFlowbite();
  },
  methods: {
    togglePexelDropdown() {
      const targetEl = document.getElementById(
        `pexelDropdown-${this.subSectionId}-${
          this.editedData._id ? this.editedData._id : ""
        }-${this.editedDataIndex ? this.editedDataIndex : ""}`
      );
      const triggerEl = document.getElementById(
        `pexelDropdownButton-${this.subSectionId}-${
          this.editedData._id ? this.editedData._id : ""
        }-${this.editedDataIndex ? this.editedDataIndex : ""}`
      );
      const options = {
        placement: "bottom",
      };
      const instanceOptions = {
        id: `pexelDropdown-${this.subSectionId}-${
          this.editedData._id ? this.editedData._id : ""
        }-${this.editedDataIndex ? this.editedDataIndex : ""}`,
        override: true,
      };
      this.dropPexelDown = new Dropdown(
        targetEl,
        triggerEl,
        options,
        instanceOptions
      );
      if (this.pexelSearch) {
        this.handleInput("landscape");
        this.dropPexelDown.toggle();
      }
    },
    handleInput(orientation) {
      this.imageLayout = orientation;
      axios
        .get(
          `https://api.pexels.com/v1/search?query=${this.pexelSearch}&orientation=${orientation}&locale=sv-SE`,
          {
            headers: {
              Authorization:
                "yyEKUFERcccV7Y75mhTcCmFdVL0TNq6eyGQffaSk6ZsWgXBeiWhaRMky",
            },
          }
        )
        .then((response) => {
          this.searchResults = response.data.photos;
          this.nextResults = response.data.next_page;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    handleNextInput() {
      axios
        .get(this.nextResults, {
          headers: {
            Authorization:
              "yyEKUFERcccV7Y75mhTcCmFdVL0TNq6eyGQffaSk6ZsWgXBeiWhaRMky",
          },
        })
        .then((response) => {
          this.searchResults = response.data.photos;
          this.nextResults = response.data.next_page;
        })
        .catch((error) => {
          console.error("Error fetching images:", error);
        });
    },
    selectPhoto(photo, editedData) {
      this.$emit("isEdited", true);
      editedData.media_url = photo.src.large;
    },
  },
};
</script>
