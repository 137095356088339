import { defineStore } from "pinia";
import { getAllTrainings } from "@/api/index.js";

export const useTrainingsStore = defineStore({
  id: "trainings",
  state: () => ({
    allTrainings: {},
  }),
  getters: {
    allActiveTrainings: (state) => {
      return Object.values(state.allTrainings).filter(
        (training) => training.active
      );
    },
  },
  actions: {
    async getAll() {
      try {
        this.allTrainings = await getAllTrainings();
        return this.allTrainings;
      } catch (error) {
        console.error("Error fetching trainings:", error);
      }
    },
  },
});
