export const SectionTypes = [
  {
    id: "video",
    title: "Video",
    icon: "ph-video-camera-duotone",
    color: "text-green-800",
    dark_color: "text-green-400",
    bg_color: "bg-green-100",
    dark_bg: "bg-gray-700",
    layouts: {
      layout1: {
        name: "Layout 1",
        text: "Klassisk layout, video åt höger",
        icons: ["ph-text-align-left", "ph-video"],
        column: false,
      },
      layout2: {
        name: "Layout 2",
        text: "Text ovan och stor video under",
        icons: ["ph-text-align-center", "ph-video"],
        column: true,
      },
      layout3: {
        name: "Layout 3",
        text: "Enbart video",
        icons: ["ph-video"],
        column: false,
      },
    },
  },
  {
    id: "image",
    title: "Bild",
    icon: "ph-image-duotone",
    color: "text-yellow-800",
    dark_color: "text-yellow-400",
    bg_color: "bg-yellow-100",
    dark_bg: "bg-gray-700",
  },
  {
    id: "texts",
    title: "Läsdel",
    icon: "ph-book-open-duotone",
    color: "text-purple-800",
    dark_color: "text-purple-400",
    bg_color: "bg-purple-100",
    dark_bg: "bg-gray-700",
  },
  {
    id: "questions",
    title: "Frågor och svar",
    icon: "ph-question-duotone",
    color: "text-blue-800",
    dark_color: "text-blue-400",
    bg_color: "bg-blue-100",
    dark_bg: "bg-gray-700",
  },
  {
    id: "pdf",
    title: "PDF",
    icon: "ph-file-pdf-duotone",
    color: "text-blue-800",
    dark_color: "text-blue-400",
    bg_color: "bg-blue-100",
    dark_bg: "bg-gray-700",
  },
];
