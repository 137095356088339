<template>
  <!-- PDF -->
  <div
    v-if="!isEditable"
    class="flex flex-col py-8 px-4 mx-auto max-w-screen-xl"
  >
    <vue-pdf-embed
      :source="`${apiUrl}${pdfFile}`"
      text-layer
      class="shadow-2xl"
    />
  </div>
  <div
    v-if="isEditable"
    class="flex flex-row gap-5 py-8 px-4 mx-auto max-w-screen-xl"
  >
    <div class="flex flex-col w-full">
      <div class="flex justify-center w-full mb-5">
        <label
          for="dropzone-file"
          class="flex flex-col justify-center w-full h-64 border-2 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700"
          @dragover.prevent="handleDragOver"
          @dragenter.prevent="handleDragEnter"
          @dragleave.prevent="handleDragLeave"
          @drop.prevent="handleDrop"
        >
          <div
            class="flex flex-col items-center justify-center pointer-events-none"
          >
            <Icon
              class="w-10 h-10 mb-4 text-gray-500 dark:text-gray-400"
              icon="ph-cloud-arrow-up"
            />
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
              <span class="font-semibold">Klicka för att ladda upp</span> eller
              dra och släpp filen här
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-400">
              PDF (Max. 30MB)
            </p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".pdf"
            @change="handleFileUpload"
          />
        </label>
      </div>
      <div>
        <ul>
          <li
            v-for="file in files"
            :key="file"
            :class="[
              file === addedPdf ? 'border-blue-700' : 'border-transparent',
            ]"
            class="flex items-center py-1 px-2 rounded-lg border-2 dark:text-white"
          >
            <Icon class="w-4 h-4 me-2" icon="ph-file-pdf" />
            <p @click="selectFile(file)" class="cursor-pointer">{{ file }}</p>
            <button class="ml-auto" @click="deleteFile(file)">
              <Icon class="w-4 h-4" icon="ph-trash" />
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div>
      <h3 class="mb-4 text-xl font-semibold dark:text-white">Visningsbild</h3>
      <vue-pdf-embed
        :source="`${apiUrl}${addedPdf}`"
        :page="1"
        :scale="2"
        :height="600"
      />
    </div>
  </div>
  <!-- END PDF -->
  <div class="flex flex-row gap-2 grow justify-center group">
    <button
      v-if="isEdited"
      @click="saveToDB"
      class="mb-0 font-medium text-sm px-5 py-2.5 text-center inline-flex items-center text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
      type="button"
    >
      Spara
    </button>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue";
import { initFlowbite } from "flowbite";
import {
  uploadTrainingPdf,
  listFilesInUploadsDirectory,
  deleteFile,
  updatePdfUrl,
} from "@/api/index.js";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  props: {
    trainingId: String,
    sectionId: String,
    subSectionId: String,
    pdfData: Object,
    isEditable: Boolean,
    sectionTitle: String,
  },
  data() {
    return {
      isEdited: false,
      isValidUrl: false,
      makeEditable: false,
      pdfFile: this.pdfData.fileUrl,
      addedPdf: "",
      files: [],
    };
  },
  computed: {
    apiUrl() {
      if (window.location.protocol === "https:") {
        return "https://" + window.location.hostname + ":4000/api/uploads/";
      } else {
        return "https://stenkoll.stendahls.se:4000/api/uploads/";
      }
    },
  },
  components: {
    Icon,
    VuePdfEmbed,
  },
  mounted() {
    initFlowbite();
    this.addedPdf = this.pdfData.fileUrl;
  },
  created() {
    this.fetchAllFiles();
  },
  methods: {
    async uploadFile(file) {
      try {
        const response = await uploadTrainingPdf(
          this.sectionId,
          this.subSectionId,
          file
        );
        console.log("File uploaded successfully");
        this.addedPdf = response.fileUrl;
        this.pdfFile = this.addedPdf;
        this.fetchAllFiles();
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },
    async deleteFile(filename) {
      try {
        await deleteFile(filename);
        this.fetchAllFiles();
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    },
    async fetchAllFiles() {
      try {
        this.files = await listFilesInUploadsDirectory();
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.uploadFile(file);
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
    },
    handleDragEnter(event) {
      event.preventDefault();
      event.target.classList.add("border-blue-500");
    },
    handleDragLeave(event) {
      event.preventDefault();
      event.target.classList.remove("border-blue-500");
    },
    handleDrop(event) {
      event.preventDefault();
      event.target.classList.remove("border-blue-500");
      const file = event.dataTransfer.files[0];
      if (file) {
        const fileName = file.name.toLowerCase();
        if (fileName.endsWith(".pdf")) {
          this.uploadFile(file);
        } else {
          console.error("Please drop a PDF file");
        }
      }
    },
    selectFile(file) {
      this.isEdited = true;
      this.addedPdf = file;
      if (this.addedPdf == this.pdfFile) {
        this.isEdited = false;
      }
    },
    async saveToDB() {
      try {
        await updatePdfUrl(this.sectionId, this.subSectionId, this.addedPdf);
        this.pdfFile = this.addedPdf;
        this.isEdited = false;
        this.fetchAllFiles();
      } catch (error) {
        console.error("Error updating URL:", error);
      }
    },
  },
};
</script>
