<template>
  <nav
    id="stenkoll-header"
    class="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-800 dark:border-gray-700 relative left-0 right-0 top-0 z-50"
  >
    <div class="flex flex-wrap justify-between items-center">
      <div class="flex justify-start items-center">
        <button
          @click="toggleSidebar"
          class="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <Icon
            v-if="!sidebarVisible"
            class="w-6 h-6"
            icon="heroicons-outline:menu-alt-1"
          />
          <svg
            v-else
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="sr-only">Toggle sidebar</span>
        </button>
        <a href="/" class="flex items-center justify-between mr-4">
          <img
            src="https://flowbite.s3.amazonaws.com/logo.svg"
            class="mr-3 h-8"
            alt="Flowbite Logo"
          />
          <span
            class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"
            >Stenkoll</span
          >
        </a>
      </div>
      <div class="flex items-center lg:order-2">
        <button
          @click="setTheme(true)"
          class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          v-if="!darkMode"
        >
          <Icon class="w-6 h-6" icon="ph:sun-dim" />
        </button>
        <button
          @click="setTheme(false)"
          v-else
          class="p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        >
          <Icon class="w-6 h-6" icon="ph:moon" />
        </button>
        <button
          type="button"
          class="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
          id="user-menu-button"
          aria-expanded="false"
          data-dropdown-toggle="dropdown"
        >
          <span class="sr-only">Open user menu</span>
          <img class="w-8 h-8 rounded-full" :src="userImage" alt="user photo" />
        </button>
        <div
          class="hidden z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow-lg dark:bg-gray-700 dark:divide-gray-600 rounded-xl"
          id="dropdown"
        >
          <div class="py-3 px-4">
            <span
              v-if="user"
              class="block text-sm font-semibold text-gray-900 dark:text-white"
              >{{ user.fullname }}</span
            >
            <span
              v-if="user"
              class="block text-sm text-gray-900 truncate dark:text-white"
              >{{ user.email }}</span
            >
          </div>
          <ul
            class="py-1 text-gray-700 dark:text-gray-300"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="#"
                class="flex items-center py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                <Icon class="mr-2" icon="ph-book-open" />
                Utförda utbildningar
              </a>
            </li>
          </ul>
          <ul
            class="py-1 text-gray-700 dark:text-gray-300"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="#"
                @click="logout"
                class="flex items-center py-2 px-4 text-sm hover:bg-red-100 dark:hover:bg-red-900 dark:rounded-b-lg dark:hover:text-white"
              >
                <Icon class="mr-2" icon="ph-sign-out" />
                Logga ut
              </a>
            </li>
            <li v-if="isAuthenticated">
              <a
                href="#"
                class="flex items-center py-2 px-4 text-sm hover:bg-red-100 dark:hover:bg-red-900 dark:rounded-b-lg dark:hover:text-white"
              >
                <Icon class="mr-2" icon="ph-sign-out" />
                Logga ut MS
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Icon } from "@iconify/vue";
import { setDarkMode, loadDarkMode } from "@/helper/theme";
import { useUserStore } from "@/store/user.js";
import { useSidebarStore } from "@/store";
import { initFlowbite, Drawer } from "flowbite";

export default {
  data() {
    return {
      darkMode: false,
      sidebar: null,
      isAuthenticated: false,
    };
  },
  computed: {
    user() {
      return useUserStore().user;
    },
    userImage() {
      return useUserStore().profileImage;
    },
    sidebarVisible() {
      return useSidebarStore().sidebarVisible;
    },
  },
  components: {
    Icon,
  },
  methods: {
    async loadUser() {
      try {
        const { success } = await useUserStore().getUser(); // Call the getUser action from the store
        if (!success) {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    setDarkMode,
    loadDarkMode,
    setTheme(bool) {
      this.darkMode = bool;
      this.setDarkMode(bool);
    },
    logout() {
      useUserStore().logout();
      this.$router.push("/login");
    },
    toggleSidebar() {
      this.sidebar.toggle();
      useSidebarStore().setSidebarState(this.sidebar.isVisible());
    },
  },
  mounted() {
    initFlowbite();
    this.loadUser();
    const sidebar = document.getElementById("drawer-navigation");
    const options = {
      onHide: () => {
        useSidebarStore().setSidebarState(false);
      },
      onShow: () => {
        useSidebarStore().setSidebarState(true);
      },
    };
    this.sidebar = new Drawer(sidebar, options);
    useSidebarStore().setSidebarState(this.sidebar.isVisible());
    useSidebarStore().setSidebar(this.sidebar);
    this.darkMode = this.loadDarkMode();
  },
};
</script>
